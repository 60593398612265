<template>
  <v-app>
   
    <Navbar :userType="loginData.user_type" :fullName="loginData.full_name" @updateLoginData="updateLoginData"/>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import Navbar from '@/components/Navbar'
  export default {
    name:'App',
    components: { Navbar},
    data (){
      return {
        loginData:{user_type:"0",full_name:""}
      }
    },
    methods:{
      updateLoginData(childLoginData){
        this.loginData = childLoginData;
        //console.log("loginData",this.loginData)
      }
    },
  mounted(){
    //console.log("mounted");
  },
    created(){
      let localLoginData = localStorage.getItem('loginData');
      if(localLoginData)
        this.loginData = JSON.parse(localStorage.getItem('loginData'));
      //console.log(this.loginData)
    },

  }
</script>