<template>
<nav v-if="isLoggedIn">
    <v-app-bar app class="panel1" elevation="0">
    <v-btn icon class="mx-1" @click.stop="TOGGLE_DRAWER">
      <template v-if="!DRAWER_STATE">
        <v-icon style="font-size: 28px">mdi-menu</v-icon>
      </template>
    </v-btn>

    <v-toolbar-title></v-toolbar-title>
    <v-spacer></v-spacer>
    <span class="white--text">{{fullName}}</span>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            dark>
                <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="menu in menus" :key="menu.title" route :to="menu.route">
              <v-icon left>{{ menu.icon }}</v-icon>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="flogout">
              <v-icon left>mdi-logout</v-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
    </v-menu>        
    </v-app-bar>

<v-navigation-drawer
    class="drawer" 
    app
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"

    >
        <v-list dense class="sidebar">
                <v-list-item>
                    <v-list-item-action>
                        <template v-if="DRAWER_STATE">
                            <v-icon  @click.stop="TOGGLE_DRAWER" style="font-size: 28px">mdi-arrow-left</v-icon>
                        </template>
                        <template v-else>
                            <v-icon @click.stop="TOGGLE_DRAWER" style="font-size: 28px">mdi-menu</v-icon>
                        </template>                        

                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <h3 class=" white--text">{{this.portalName[this.userType]}}</h3>
                        </v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            </v-list>
          
        <template v-for="item in items">
        <v-list-group no-action :value="false" :key="item.title" v-if="item.sub && item.user_type[userType]">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon class="white--text">{{item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="white--text caption">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <template v-for="submenu in item.submenu">
                <v-list-item :key="submenu.title" router :to="submenu.href" v-if="submenu.user_type[userType]">
                    <v-list-item-title class="white--text caption">
                        <v-icon x-small class="white--text">mdi-minus</v-icon>
                        {{ submenu.title }}</v-list-item-title>
                </v-list-item>
            </template>
        </v-list-group>
        <v-list  v-if="!item.sub && item.user_type[userType]" :key="item.title">
            <v-list-item router :to="item.href">
                <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title  class="white--text caption">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        </template>
         
    </v-navigation-drawer>    
</nav>
</template>

<script>
//import {mapGetters} from 'vuex'
import {mapActions, mapState} from 'vuex'
import ApParameterService from "@/services/ApParameterService"
export default {
    props: {
        userType:String,
        fullName:String,
    },     
  data() {
    return {
      sidebarMenu:true,
      sidebarMinWidth:96,
      sidebarWidth:280,
      toggleMini:false,
      portalName:{"1":"Admin Portal","2":"Client Portal","99":"Admin Portal-SU",},
      items: [
        { sub:false,title:"Dashboard", href:"/dashboard", icon:"mdi-monitor-dashboard",user_type:{"1":true,"2":true,"99":true} },
        { sub:true,title:"Customers", href:"/customers", icon:"mdi-account-group",user_type:{"1":true,"2":true,"99":true},
            submenu:[
                {title:"Recent List", href:"/customers/recentlist",user_type:{"1":true,"2":true,"99":true}},
                {title:"Search", href:"/customers/search",user_type:{"1":true,"2":true,"99":true}},
                {title:"Registration", href:"/customers/registration",user_type:{"1":false,"2":true,"99":true}},
            ]
        },       
        { sub:true,title:"Merchants", href:"/merchants", icon:"mdi-store",user_type:{"1":true,"2":true,"99":true},
            submenu:[
                {title:"List", href:"/merchants",user_type:{"1":true,"2":true,"99":true}},
                {title:"Outlet List", href:"/merchants/outlets/",user_type:{"1":true,"2":true,"99":true}},
                {title:"EDC List", href:"/merchants/edc/",user_type:{"1":true,"2":true,"99":true}},
                {title:"EDC Receipt Template", href:"/merchants/edcrt/",user_type:{"1":true,"2":true,"99":true}},
                {title:"User List", href:"/merchants/users/",user_type:{"1":true,"2":true,"99":true}},
            ] },
        { sub:true,title:"Catalogues", href:"/catalogues", icon:"mdi-image-multiple",user_type:{"1":true,"2":true,"99":true}, 
            submenu:[
                {title:"List", href:"/catalogues/",user_type:{"1":true,"2":true,"99":true}},
                {title:"Catalogue Item", href:"/catalogues/items",user_type:{"1":true,"2":true,"99":true}},
                {title:"Supplier List", href:"/suppliers/",user_type:{"1":true,"2":true,"99":true}},
            ]

        },
        { sub:true,title:"Careline", href:"/", icon:"mdi-face-agent",user_type:{"1":true,"2":true,"99":true},
            submenu:[
                {title:"Add Enquiry", href:"/callcenter/add",user_type:{"1":true,"2":true,"99":true}},
                {title:"Recent Enquiry", href:"/callcenter/recentlist",user_type:{"1":true,"2":true,"99":true}},
                {title:"Search Enquiry", href:"/callcenter/search",user_type:{"1":true,"2":true,"99":true}},
            ]
        },        
        { sub:true,title:"Marketing", href:"/customers", icon:"mdi-bullhorn",user_type:{"1":true,"2":true,"99":true},
            submenu:[
                {title:"Banner", href:"/marketing/banners",user_type:{"1":true,"2":true,"99":true}},
                {title:"Page", href:"/marketing/pages",user_type:{"1":true,"2":true,"99":true}},
                // {title:"Email", href:"/"},
            ]
        },        
        { sub:true,title:"Notification", href:"/customers", icon:"mdi-bell",user_type:{"1":true,"2":true,"99":true},
            submenu:[
                {title:"Category", href:"/notification/categories",user_type:{"1":true,"2":true,"99":true}},
                {title:"Template", href:"/notification/templates",user_type:{"1":true,"2":true,"99":true}},
                {title:"Broadcast", href:"/notification/broadcast",user_type:{"1":true,"2":true,"99":true}},
                // {title:"System Message", href:"/notification/sysmsg"},
                {title:"Setting", href:"/notification/settings",user_type:{"1":true,"2":true,"99":true}},
            ]
        },
        { sub:true,title:"Transactions", href:"/transactions", icon:"mdi-sale",user_type:{"1":true,"2":true,"99":true}, 
            submenu:[
                {title:"Recent List", href:"/transactions/recentlist",user_type:{"1":true,"2":true,"99":true}},
                {title:"Search", href:"/transactions/search",user_type:{"1":true,"2":true,"99":true}},
                {title:"Manual Issuance", href:"/transactions/issuance",user_type:{"1":true,"2":true,"99":true}},
            ] 
        },
        { sub:true,title:"Redemptions", href:"/redemptions", icon:"mdi-gift",user_type:{"1":true,"2":true,"99":true}, 
            submenu:[
                {title:"Recent List", href:"/redemptions/recentlist",user_type:{"1":true,"2":true,"99":true}},
                {title:"Search", href:"/redemptions/search",user_type:{"1":true,"2":true,"99":true}},
                // {title:"Manual Redemption", href:"/",user_type:{"1":true,"2":true,"99":true}},
            ] 
        },
        { sub:true,title:"Point Settings", href:"/ptssettings", icon:"mdi-plus-circle-multiple",user_type:{"1":true,"2":true,"99":true}, 
            submenu:[
                {title:"Pts Campaign", href:"/ptssettings/campaigns/",user_type:{"1":true,"2":true,"99":true}},
                {title:"Pts Group", href:"/ptssettings/groups/",user_type:{"1":true,"2":true,"99":true}},
                {title:"Pts Model", href:"/ptssettings/models/",user_type:{"1":true,"2":true,"99":true}},
            ]
        },
        { sub:true,title:"Batch Upload", href:"/", icon:"mdi-file-upload",user_type:{"1":true,"2":true,"99":true}, 
            submenu:[
                {title:"Member Registration", href:"/fileupload/list/member_registration",user_type:{"1":true,"2":true,"99":true}},
                {title:"Member Update", href:"/fileupload/list/member_update",user_type:{"1":true,"2":true,"99":true}},
                {title:"Point File", href:"/fileupload/list/point_file",user_type:{"1":true,"2":true,"99":true}},
                {title:"Upload Template", href:"/fileupload/templates",user_type:{"1":true,"2":true,"99":true}},
            ]
        },        
        { sub:true,title:"Settings", href:"/settings", icon:"mdi-cog",user_type:{"1":true,"2":true,"99":true}, 
            submenu:[
                {title:"User", href:"/users/",user_type:{"1":true,"2":true,"99":true}},
                {title:"User Group", href:"/users/groups",user_type:{"1":true,"2":true,"99":true}},
                {title:"System Parameter", href:"/settings",user_type:{"1":true,"2":true,"99":true}},
                {title:"TX Email Settings", href:"/txemail",user_type:{"1":true,"2":true,"99":true}},
            ] 
        },
        { sub:true,title:"Logs", href:"/syslogs", icon:"mdi-desktop-classic",user_type:{"1":true,"2":true,"99":true}, 
            submenu:[
                {title:"Member Update", href:"/logs/mbrupdate",user_type:{"1":true,"2":true,"99":true}},
                {title:"Member Token", href:"/logs/mbrtoken",user_type:{"1":true,"2":true,"99":true}},
                {title:"Email", href:"/logs/email",user_type:{"1":true,"2":true,"99":true}},
                {title:"Notification", href:"/logs/notification",user_type:{"1":true,"2":true,"99":true}},
                {title:"File Upload Validation", href:"/logs/fuvalidation",user_type:{"1":true,"2":true,"99":true}},
            ] 
        },
      ],
      menus:[
          { title:"Profile", route:"/admin/profile", icon:"mdi-account-edit" },
          { title:"Change Password", route:"/admin/changepassword", icon:"mdi-lock" },
      ],
        loginData:[],        
        events:['click','mousemove'],
    }
  },
      computed: {
      mini() {
        return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
      },
      //...mapGetters(['gLogin']),
      isLoggedIn : function(){ return this.$store.getters.gLogin},

      ...mapState(['drawer']),
      DRAWER_STATE: {
        get() {
          return this.drawer
        },
        set(newValue) {
          if (newValue === this.drawer) return;
          this.TOGGLE_DRAWER();
        }
      }

     
    },
    watch:{
        isLoggedIn: function(){
            //console.log(this.$store.state.isLogin);
            let checkLoginData = localStorage.getItem('loginData');
            let childLoginData
            //console.log('checkLoginData',checkLoginData);
            if(checkLoginData){
                this.loginData = JSON.parse(localStorage.getItem('loginData'));
                childLoginData = {user_type:this.loginData['user_type'],full_name:this.loginData['full_name']}
                this.$emit("updateLoginData",childLoginData);
            }else{
                this.loginData = [];
                childLoginData = {user_type:"0",full_name:""}
                this.$emit("updateLoginData",childLoginData);
            }
            
            
            
        }
    },
    methods:{
        ...mapActions([ 'TOGGLE_DRAWER' ]),
      flogout(){
        //console.log(this.loginData);
        localStorage.removeItem('token_auth');
        localStorage.removeItem('loginData');
        localStorage.removeItem('login_id');
        localStorage.removeItem('isLogin');
        //this.$store.dispatch('loginState',null);
        this.$store.dispatch('aLogout');
        
        this.$router.push('/login')
        //this.loginData = null;
        
      },

        async getParameter(){
            try{
                let parameter = {login_id:"",param_type:"sys_ap_loyalty_nav_menu"}
                ApParameterService.get_parameter_v2(parameter).then((res) => {
                    this.items = res.data.menuList;
                    //console.log(this.items);

                }).catch((e)=>{
                    if(e)
                        alert("download menu failed!")
                    //console.log(e,"download parameter error");
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        },        

    },
    mounted(){
       },
    
    
    async created() {
    //   let localLoginData = localStorage.getItem('loginData');
    //   if(localLoginData)
    //     this.loginData = JSON.parse(localStorage.getItem('loginData'));
    //   //console.log(this.loginData)

        this.getParameter();
    },


}
</script>

<style>
</style>